<router-outlet></router-outlet>

<call-popup-v2
  *ngIf="pollerService.leadInValidation$ | async as lead"
  [lead]="lead"
></call-popup-v2>
<hot-lead-popup
  *ngIf="(pollerService.hotPopupAvailable$ | async) && !!!(pollerService.leadInValidation$ | async)"
></hot-lead-popup>
<no-lead-message *ngIf="pollerService.showUnsuccesfulLockMessage$ | async"></no-lead-message>
