import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, TitleCasePipe, DecimalPipe } from '@angular/common';
import { RdrDatePipe } from '@ui/pipes/rdr-date.pipe';
import { RdrCurrencyPipe } from '@ui/pipes/rdr-currency.pipe';
import { UserNamePipe } from '@ui/pipes/user-name.pipe';
import { IsDateTodayPipe } from '@ui/pipes/is-date-today.pipe';
import { IsDateBeforePipe } from '@ui/pipes/is-date-before.pipe';
import { ExtractNodesPipe } from '@shared/pipes/extract-node.pipe';
import { ExtractConnectionPipe } from '@shared/pipes/extract-connection.pipe';
import { SortByPropertyPipe } from '@shared/pipes/sort-by-property.pipe';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { EnsureObservablePipe } from '@shared/pipes/ensure-observables.pipe';
import { JoinPropertyPipe } from '@shared/pipes/join-property.pipe';
import { LabelPipe } from '@shared/pipes/label.pipe';
import { CreditCardTitlePipe } from '@shared/pipes/cost-method.pipe';
import { TravelTimeFormatPipe } from '@shared/pipes/travel-time-format.pipe';
import { BookingTypePipe } from '@shared/pipes/booking-type.pipe';
import { OperationLabelPipe } from '@shared/pipes/operation-label.pipe';
import { FlightInfoPipe } from '@shared/pipes/flight-info-pipe.pipe';
import { SeatsFromSegmentPipe } from '@shared/pipes/seats-from-segment.pipe';
import { CostSupplierPipe } from '@shared/pipes/cost-supplier.pipe';
import { ExtractETicketsPipe } from './extract-etickets.pipe';
import { TrimFirstWordPipe } from './trim-first-word.pipe';
import { BooleanToYesNoPipe } from './boolean-to-yes-no.pipe';
import { OrderStatusIsOptionPipe } from './order-status-is-option.pipe';
import { CreatedAtPipe } from './created-at.pipe';
import { CcTypePipe } from './cc-type.pipe';
import { UpdateUserSelectionListPipe } from './update-user-selection-list.pipe';
import { ToDataIdPipe } from './to-data-id.pipe';
import { IsReadonlyCostsPipe } from '@app/shared/pipes/is-readonly-costs.pipe';
import { FlexibilityPipe } from '@app/shared/pipes/flexibility.pipe';
import { IsRoundTripPipe } from '@app/shared/pipes/is-round-trip.pipe';
import { DurationPipe } from '@app/shared/pipes/duration.pipe';
import { IsAfterExtraLegSegmentPipe } from '@app/shared/pipes/is-after-extra-leg-segment.pipe';
import { LoadUserById } from '@app/pages/flight-requests/pipes/load-user-by-id.pipe';
import { GetDaysShiftPipe } from '@app/shared/pipes/get-days-shift.pipe';
import { MinutesFormatPipe } from './minutes-format.pipe';
import { MsToTimePipe } from './ms-to-time.pipe';
import { ShortFullNamePipe } from './short-full-name.pipe';

const pipes = [
  RdrDatePipe,
  RdrCurrencyPipe,
  UserNamePipe,
  IsDateTodayPipe,
  IsDateBeforePipe,
  ExtractNodesPipe,
  ExtractConnectionPipe,
  SortByPropertyPipe,
  FilterPipe,
  EnsureObservablePipe,
  JoinPropertyPipe,
  LabelPipe,
  CreditCardTitlePipe,
  TravelTimeFormatPipe,
  BookingTypePipe,
  OperationLabelPipe,
  FlightInfoPipe,
  SeatsFromSegmentPipe,
  CostSupplierPipe,
  ExtractETicketsPipe,
  TrimFirstWordPipe,
  LoadUserById,
  BooleanToYesNoPipe,
  OrderStatusIsOptionPipe,
  CreatedAtPipe,
  CcTypePipe,
  UpdateUserSelectionListPipe,
  ToDataIdPipe,
  IsReadonlyCostsPipe,
  FlexibilityPipe,
  IsRoundTripPipe,
  DurationPipe,
  IsAfterExtraLegSegmentPipe,
  GetDaysShiftPipe,
  MinutesFormatPipe,
  MsToTimePipe,
  ShortFullNamePipe,
];

@NgModule({
  providers: [
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    CreditCardTitlePipe,
    UserNamePipe,
    LabelPipe,
    ExtractNodesPipe,
    DurationPipe,
  ],
  declarations: [pipes],
  imports: [CommonModule],
  exports: [pipes],
})
export class PipesModule {}
